import React from "react"
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

import HTML from '../components/html';

import MoreLink from "../components/moreLink";

import group1 from '../images/gth1.jpg';
import group2 from '../images/gth2.jpg';
import group3 from '../images/gth3.jpg';
import przewodnik from '../images/przewodnik.png';

const ForGroupsPage = ({ intl }) => {
	return (
		<Layout pageName="prices" siteTitle="trekking.page_title" intro="">
			<SEO
				lang={intl.locale}
				title={intl.formatMessage({ id: "title" })}
				description={intl.formatMessage({ id: "description" })}
				keywords={[`gatsby`, `application`, `react`]}
			/>
		
			<section className="wrapper container wrap narrow">
				<div className="solidBg overlap">
					<p><FormattedMessage
						id="trekking.p1"
						defaultMessage={intl.formatMessage({ id: "forGroups.p1" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</p>
					<p><FormattedMessage
						id="trekking.p2"
						defaultMessage={intl.formatMessage({ id: "forGroups.p2" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</p>
					<p> Z naszą firmą <a href="https://www.gettohikes.com" target="_blank"><strong>www.gettohikes.com </strong>🚌</a> z łatwością dotrzecie do jednych z najpiękniejszych szlaków trekkingowych na Maderze!</p>
					<img className="trip-map center" src={group1} />
					<img className="trip-map center" src={group2} />
					<img className="trip-map center" src={group3} />

					<p id="trek-details">Oferta zawiera transfer na trasy:</p>

					<p>
						<strong>1. Trzy szczyty: PR 1 + PR 1.2 - Pico do Areeiro - Pico Ruivo - Achada do Teixeira</strong>
						<MoreLink to="https://gettohikes.com/pl/inspiration/pico-do-arieiro-pico-ruivo-achada-do-teixeira-pr-1-pr-1-2-1"/><br/>
					</p>

					<p>
						<strong>2. Dolina Rabaçal: PR 6:</strong><br/>
						- Levada 25 Źródeł i Wodospad Risco
						<MoreLink to="https://gettohikes.com/pl/inspiration/rabacal-wodospad-risco-levada-25-zrodel-pr-6-1-pr-6"/><br/>
						- Levada do Alecrim i Wodospad Risco
						<MoreLink to="https://gettohikes.com/pl/inspiration/rabacal-levada-do-alecrim-lagoa-do-vento-wodospad-risco-pr-6-2-6-3-6-1"/><br/>
						- Levada da Rocha Vermelha
						<MoreLink to="https://gettohikes.com/pl/inspiration/rabacal-levada-da-rocha-vermelha"/><br/>
					</p>

					<p>
						<strong>3. Las Fanal:</strong><br/>
						- PR 13 - Vereda do Fanal
						<MoreLink to="https://gettohikes.com/pl/inspiration/nowosc-dostepne-wkrotce-fanal-vereda-do-fanal-pr-13"/><br/>
						- PR14 - Levada dos Cedros
						<MoreLink to="https://gettohikes.com/pl/inspiration/nowosc-dostepne-wkrotce-fanal-levada-dos-cedros-pr-14"/><br/>
						- Czas wolny w lesie Fanal - opcja bez trekkingu
						<MoreLink to="https://gettohikes.com/pl/inspiration/nowosc-dostepne-wkrotce-fanal-bez-trekkingu-czas-wolny-w-fanal"/><br/>
					</p>

					<p>
						Do autokaru będziecie mogli wsiąść z kilku różnych przystanków w Funchal oraz w Garajau, Caniço de Baixo, i Ribeira Brava, następnie zawieziemy Was na punkt startowy trasy, a po skończonej wycieczce odbierzemy z punktu końcowego danego szlaku.
					</p>

					<p>
						Zapraszamy na <a href="https://www.instagram.com/get_to_hikes_madeira/" target="_blank"> <strong>Instagram</strong> </a> i <a href="http://fb.me/GetToHikes" target="_blank"><strong>Facebook</strong></a> po więcej inspiracji!
					</p>

					<p><br/><br/>
					<strong>Eksploruj Maderę z przewodnikiem naszego partnera „Couple Away”! </strong><br/>
					<a href="https://coupleaway.com/przewodnik-madera/" target="_blank"><img src={przewodnik} style={{height: "200px", width: "auto", float: "left", marginTop: "0.5em"}}/></a>
						<br/>Ten kompleksowy przewodnik jest Twoim kluczem do poznania cudów Madery. Zawiera mnóstwo bezcennych informacji, wskazówek i spostrzeżeń oraz obejmuje mnóstwo atrakcji, w tym różnorodnych szlaków turystycznych prezentujących piękno wyspy.
						<br/>
						Od szczegółowych opisów różnych wędrówek po niezbędne wskazówki dotyczące różnych aktywności – ten przewodnik to Twój spersonalizowany plan prowadzący do niezapomnianej przygody na Maderze. 
					</p>
					
				</div>
			</section>
		</Layout>
	)
}

export default injectIntl(ForGroupsPage)
